import React, { useContext, useState } from "react";
import Sidebar from "./components/Sidebar";
import { Card, FormControl, InputGroup } from "react-bootstrap";
import { MarketPlaceContext } from "../../context/marketPlaceContext";
import { useNavigate } from "react-router-dom";
import TransactionTableList from "./components/TransactionTableList";
import { CiBoxList } from "react-icons/ci";

const Summary = () => {
  const { summary, transactions, sellers } = useContext(MarketPlaceContext);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const cardColors = [
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "dark",
  ];
  const summaryKeys = Object.keys(summary);

  const handleCardClick = (key) => {
    switch (key) {
      case "sellers":
        navigate("/marketplace/merchant");
        break;
      case "agents":
        navigate("/marketplace/agent");
        break;
      case "markets":
        navigate("/marketplace/market");
        break;
      default:
        break;
    }
  };

  const getMerchantName = (merchantId) => {
    const merchant = sellers.find((seller) => seller._id === merchantId);
    return merchant ? merchant.name : "";
  };

  const filteredTransactions = transactions.filter((transaction) => {
    const transactionRef = transaction.transactionRef.toLowerCase() || "";
    const merchantName = getMerchantName(transaction.merchantId).toLowerCase();
    return (
      transactionRef.includes(searchQuery) ||
      merchantName.includes(searchQuery.toLowerCase())
    );
  });

  return (
    <div className="d-flex summary">
      <Sidebar />
      <div
        className="content-container"
        style={{ flex: 1, marginLeft: "280px" }}
      >
        <div className="p-3">
          <div
            style={{ marginBottom: "20px" }}
            className="d-flex justify-content-between align-items-center"
          >
            <div className="custom-flex">
              <h2 className="mb-0">Summary</h2>
              <CiBoxList size={25} />
            </div>
          </div>
          <div
            className="summary-cards"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "10px",
              marginBottom: "40px",
            }}
          >
            {summaryKeys.map((key, index) => {
              const color = cardColors[index % cardColors.length];
              return (
                <Card
                  bg="#454545"
                  key={key}
                  text={color === "light" ? "dark" : "white"}
                  style={{ width: "100%", cursor: "pointer" }}
                  onClick={() => handleCardClick(key)}
                >
                  <Card.Header>
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </Card.Header>
                  <Card.Body>
                    <Card.Title>{summary[key]}</Card.Title>
                    <Card.Text>Total {key}</Card.Text>
                  </Card.Body>
                </Card>
              );
            })}
          </div>
          <InputGroup
            style={{
              maxWidth: "450px",
              fontSize: "14px",
            }}
            className="mb-3"
          >
            <FormControl
              placeholder="Search by Merchant Name or Transaction Ref"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </InputGroup>
          <TransactionTableList data={filteredTransactions} />
        </div>
      </div>
    </div>
  );
};

export default Summary;
