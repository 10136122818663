import React, { useContext, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MarketPlaceContext } from "../../../context/marketPlaceContext";
import { MdRealEstateAgent } from "react-icons/md";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import axxios from "../../../api/axxios";

function TableList({ data }) {
  const { paymentPlan, agents } = useContext(MarketPlaceContext);
  const [isPayModalOpened, setIsPayModalOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const staffId = sessionStorage.getItem("adminCode");

  // Convert paymentPlan object to an array of plan objects for easier lookup
  const paymentPlansArray = Object.keys(paymentPlan)
    .filter((key) => key !== "thresholdAmount")
    .map((key) => paymentPlan[key]);

  const getPaymentPlanName = (planId) => {
    const plan = paymentPlansArray.find((plan) => plan.id === planId);
    return plan ? plan.name : planId;
  };

  const handlePayment = (merchantId, marketId) => {
    setIsPayModalOpened(true);
    paymentFormik.setFieldValue("merchantId", merchantId);
    paymentFormik.setFieldValue("marketId", marketId);
  };

  const paymentFormik = useFormik({
    initialValues: {
      amountPaid: "",
      merchantId: "",
      agentId: "",
      marketId: "",
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        await axxios.post(
          `/merchants/payment/collect?staffId=${staffId}`,
          values
        );
        toast.success("payment successful", {
          position: toast.POSITION.TOP_RIGHT,
          toastId: "Success",
        });
      } catch (err) {
        const errorMsg =
          err.message === "Network Error"
            ? "Network Error"
            : err.response.data.error || "Payment error";
        toast.error(errorMsg, {
          position: toast.POSITION.TOP_LEFT,
          toastId: "Error",
        });
      } finally {
        setIsLoading(false);
        paymentFormik.resetForm();
        setIsPayModalOpened(false);
      }
    },
  });

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Phone</th>
            <th>Plan</th>
            <th>Facility plan</th>
            <th>Payment</th>
            <th>Has Paid</th>
            <th>Owed</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item._id}>
              <td>{index + 1}</td>
              <td>{item.name}</td>
              <td>{item.phoneNumber}</td>
              <td>{item.healthPlan}</td>
              <td>{item.facility}</td>
              <td>
                {getPaymentPlanName(Number(item.paymentPlan)) ||
                  item.paymentPlan}
              </td>
              <td style={{ color: item.hasPaid ? "green" : "red" }}>
                {item.hasPaid ? "true" : "false"}
              </td>
              <td>₦{item.amountOwed}</td>
              <td className="table-action">
                <Link
                  to={`/marketplace/merchant/${item._id}`}
                  className="btn btn-warning"
                >
                  Edit
                </Link>
                <button
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                  onClick={() => handlePayment(item._id, item.marketName)}
                  className="btn btn-info"
                  disabled={item.hasPaid}
                >
                  <MdRealEstateAgent />
                  Pay
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Dialog
        header="Payment"
        visible={isPayModalOpened}
        className="search-bar"
        onHide={() => setIsPayModalOpened(false)}
        draggable={false}
      >
        <form className="mt-4" onSubmit={paymentFormik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              name="amountPaid"
              value={paymentFormik.values.amountPaid}
              onChange={paymentFormik.handleChange}
              placeholder="Enter amount"
              className="search-bar"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Phone Number</Form.Label>
            <Form.Select
              name="agentId"
              onChange={paymentFormik.handleChange}
              required
              aria-label=""
            >
              <option>Select agent</option>
              {agents.map((agent) => (
                <option key={agent._id} value={agent._id}>
                  {agent.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Button
            type="submit"
            variant="success"
            className="rounded-0 mt-3 w-100"
          >
            {isLoading ? "Please wait" : "Continue"}
          </Button>
        </form>
      </Dialog>
    </>
  );
}

export default TableList;
