import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Footer, Navbar } from "../components";
import { EnrolleeContext } from "../context/enrolleeContext";
import BarLoader from "react-spinners/BarLoader";
import { useNavigate } from "react-router-dom";
import axxios from "../api/axxios";
import { useFormik } from "formik";
import { ToastContainer, Zoom, toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import Form from "react-bootstrap/Form";

const PrimaryResult = () => {
  const { enrolleeData, isLoading, dataAvailable } =
    useContext(EnrolleeContext);

  const [verifyStaffModal, setVerifyStaffModal] = useState(false);
  const [id, setId] = useState("");

  const navigate = useNavigate();

  const staffFormik = useFormik({
    initialValues: {
      staffCode: "",
    },
    onSubmit: async (values) => {
      await axxios
        .get(`/users/verify/${values.staffCode}`)
        .then((data) => {
          setVerifyStaffModal(false);
          toast.success("Staff Verification Successful", {
            position: toast.POSITION.TOP_LEFT,
            toastId: "Success",
          });
          navigate(`/enrollee/details/edit/${id}`);
        })
        .catch((err) => {
          setVerifyStaffModal(false);
          if (err.message === "Network Error") {
            toast.error("Network Error", {
              position: toast.POSITION.TOP_LEFT,
              toastId: "Error",
            });
          } else if (err.response.data.message === "Access Denied!") {
            toast.error("Access Denied!", {
              position: toast.POSITION.TOP_LEFT,
              toastId: "Error",
            });
          } else {
            toast.error("Staff ID not found", {
              position: toast.POSITION.TOP_LEFT,
              toastId: "Error",
            });
          }
        });
    },
  });

  return (
    <>
      <Dialog
        header="Enrollee verification"
        visible={verifyStaffModal}
        className="search-bar"
        onHide={() => setVerifyStaffModal(false)}
        draggable={false}
      >
        <form className="m-0" onSubmit={staffFormik.handleSubmit}>
          <Form.Label className="mb-3" htmlFor="id">
            Verification Code
          </Form.Label>
          <Form.Control
            type="text"
            name="staffCode"
            placeholder="Enter code to verify"
            onChange={staffFormik.handleChange}
            value={staffFormik.values.staffCode}
            onBlur={staffFormik.handleBlur}
          />
          <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-3 w-100 ms-auto">
            <button
              type="submit"
              className="btn btn-success w-100"
              disabled={!staffFormik.values.staffCode}
            >
              {staffFormik.isSubmitting ? "Verifying" : "Verify"}
            </button>
          </div>
        </form>
      </Dialog>
      {isLoading && (
        <div className="loading">
          <Navbar />
          <div className="search-input d-flex justify-content-center align-items-center">
            <BarLoader
              color={"#59C1BD"}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </div>
      )}
      {dataAvailable && (
        <>
          <Navbar />
          <div className="container mb-4 mt-4">
            <div>
              {enrolleeData.map(
                (singlePerson, i) =>
                  singlePerson.dependantType === "None" && (
                    <Card key={i} className="mb-2">
                      <Card.Header>Principal</Card.Header>
                      <Card.Body>
                        <Card.Title>{`${singlePerson.firstName} ${singlePerson.lastName}`}</Card.Title>
                        <Card.Text>{`${singlePerson.gender}`}</Card.Text>
                        <Card.Text>{`${singlePerson.dob}`}</Card.Text>
                        <Card.Text>{`${singlePerson.package}`}</Card.Text>
                        <div className="primary__buttons">
                          <Button
                            onClick={() =>
                              navigate(`/enrollee/details/${singlePerson.id}`)
                            }
                            variant="success"
                          >
                            View More
                          </Button>
                          <Button
                            onClick={() => {
                              setId(singlePerson.id);
                              sessionStorage.getItem("isLive") === "True"
                                ? setVerifyStaffModal(true)
                                : navigate(
                                    `/enrollee/details/edit/${singlePerson.id}`
                                  );
                            }}
                            variant="success"
                          >
                            Edit
                          </Button>
                        </div>
                      </Card.Body>
                    </Card>
                  )
              )}
            </div>
            <div>
              <h4 className="mb-2 mt-4">BENEFICIARY</h4>
              <div className="primary-result">
                {enrolleeData.length > 1 ? (
                  enrolleeData.map(
                    (singlePerson, i) =>
                      singlePerson.dependantType !== "None" && (
                        <Card key={i} className="primary-card mb-2">
                          <Card.Header>{`${singlePerson.dependantType}`}</Card.Header>
                          <Card.Body>
                            <Card.Title>{`${singlePerson.firstName} ${singlePerson.lastName}`}</Card.Title>
                            <Card.Text>{`${singlePerson.gender}`}</Card.Text>
                            <Card.Text>{`${singlePerson.dob}`}</Card.Text>
                            {/* <Card.Text>{`${singlePerson.package}`}</Card.Text> */}
                            <div className="primary__buttons">
                              <Button
                                onClick={() =>
                                  navigate(
                                    `/enrollee/details/${singlePerson.id}`
                                  )
                                }
                                variant="success"
                              >
                                View More
                              </Button>
                              <Button
                                onClick={() => {
                                  setId(singlePerson.id);
                                  sessionStorage.getItem("isLive") === "True"
                                    ? setVerifyStaffModal(true)
                                    : navigate(
                                        `/enrollee/details/edit/${singlePerson.id}`
                                      );
                                }}
                                variant="success"
                              >
                                Edit
                              </Button>
                            </div>
                          </Card.Body>
                        </Card>
                      )
                  )
                ) : (
                  <p>No beneficiaies Found!</p>
                )}
              </div>
            </div>
          </div>
          <Footer />
          <ToastContainer
            draggable={false}
            autoClose={3000}
            transition={Zoom}
          />
        </>
      )}
    </>
  );
};

export default PrimaryResult;
