/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Search from "./pages/Search";
import SearchResult from "./pages/SearchResult";
import PrimaryResult from "./pages/PrimaryResult";
import EditEnrolleDetails from "./pages/EditEnrolleDetails";
import { useEffect, useContext } from "react";
import { EnrolleeContext } from "./context/enrolleeContext";
import GenerateStaffId from "./pages/GenerateStaffId";
import StaffLogin from "./pages/StaffLogin";
import StaffDashboard from "./pages/StaffDashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import MarketPlace from "./pages/marketplace/MarketPlace";
import Merchant from "./pages/marketplace/Merchant";
import Agent from "./pages/marketplace/Agent";
import AddMerchant from "./pages/marketplace/AddMerchant";
import EditMerchant from "./pages/marketplace/EditMerchant";
import AdminProtectedRoute from "./components/AdminProtectedRoute";
import Summary from "./pages/marketplace/Summary";
import Markets from "./pages/marketplace/Markets";
import AddMarket from "./pages/marketplace/AddMarket";
import EditMarket from "./pages/marketplace/EditMarket";
import AddAgent from "./pages/marketplace/AddAgent";
import EditAgent from "./pages/marketplace/EditAgent";

function App() {
  const { setRadioValue } = useContext(EnrolleeContext);

  useEffect(() => {
    if (sessionStorage.getItem("isLive") === "True") {
      setRadioValue("2");
    } else setRadioValue("1");
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Search />} />
        <Route exact path="/enrollee" element={<PrimaryResult />} />
        <Route exact path="/enrollee/details/:id" element={<SearchResult />} />
        <Route
          exact
          path="/enrollee/details/edit/:id"
          element={<EditEnrolleDetails />}
        />
        <Route exact path="/staff/generate_id" element={<GenerateStaffId />} />
        <Route exact path="/staff/login" element={<StaffLogin />} />
        <Route
          exact
          path="/dashboard/corporate"
          element={
            <ProtectedRoute>
              <StaffDashboard />
            </ProtectedRoute>
          }
        />

        {/* marketplace  */}
        <Route exact path="/marketplace" element={<MarketPlace />} />
        <Route
          exact
          path="/marketplace/summary"
          element={
            <AdminProtectedRoute>
              <Summary />
            </AdminProtectedRoute>
          }
        />
        <Route
          exact
          path="/marketplace/merchant"
          element={
            <AdminProtectedRoute>
              <Merchant />
            </AdminProtectedRoute>
          }
        />
        <Route
          exact
          path="/marketplace/merchant/create"
          element={
            <AdminProtectedRoute>
              <AddMerchant />
            </AdminProtectedRoute>
          }
        />
        <Route
          exact
          path="/marketplace/merchant/:id"
          element={
            <AdminProtectedRoute>
              <EditMerchant />
            </AdminProtectedRoute>
          }
        />
        <Route
          exact
          path="/marketplace/agent"
          element={
            <AdminProtectedRoute>
              <Agent />
            </AdminProtectedRoute>
          }
        />
        <Route
          exact
          path="/marketplace/agent/create"
          element={
            <AdminProtectedRoute>
              <AddAgent />
            </AdminProtectedRoute>
          }
        />
        <Route
          exact
          path="/marketplace/agent/:id"
          element={
            <AdminProtectedRoute>
              <EditAgent />
            </AdminProtectedRoute>
          }
        />
        <Route
          exact
          path="/marketplace/market"
          element={
            <AdminProtectedRoute>
              <Markets />
            </AdminProtectedRoute>
          }
        />
        <Route
          exact
          path="/marketplace/market/create"
          element={
            <AdminProtectedRoute>
              <AddMarket />
            </AdminProtectedRoute>
          }
        />
        <Route
          exact
          path="/marketplace/market/:id"
          element={
            <AdminProtectedRoute>
              <EditMarket />
            </AdminProtectedRoute>
          }
        />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
