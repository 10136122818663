import React, { useState, createContext } from "react";

const EnrolleeContext = createContext();

const EnrolleeProvider = ({ children }) => {
  const [enrolleeData, setEnrolleeData] = useState([]);
  const [dataAvailable, setDataAvailable] = useState(false);
  const [enrolleeDataDetail, setEnrolleeDataDetail] = useState({});
  const [dataDetailAvailable, setDataDetailAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [radioValue, setRadioValue] = useState("2");

  return (
    <EnrolleeContext.Provider
      value={{
        enrolleeData,
        setEnrolleeData,
        dataAvailable,
        setDataAvailable,
        isLoading,
        setIsLoading,
        isError,
        setIsError,
        errMessage,
        setErrMessage,
        enrolleeDataDetail,
        setEnrolleeDataDetail,
        dataDetailAvailable,
        setDataDetailAvailable,
        radioValue,
        setRadioValue,
      }}
    >
      {children}
    </EnrolleeContext.Provider>
  );
};

export { EnrolleeContext, EnrolleeProvider };
