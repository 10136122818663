import React, { useState, useEffect } from "react";
import { Navbar } from "../components";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axxios from "../api/axxios";
import { BarLoader } from "react-spinners";
import { Paginator } from "primereact/paginator";
import { Button, Dropdown, Form } from "react-bootstrap";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import { ToastContainer, Zoom, toast } from "react-toastify";

const StaffDashboard = () => {
  const [corporate, setCorporate] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const id = sessionStorage.getItem("staffCode");
  const [page, setPage] = useState(1);
  const [first, setFirst] = useState(0);
  const [perPage, setPerPage] = useState(15);
  const [totalRecords, setTotalRecords] = useState(0);
  const [addDriverModal, setAddDriverModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [chooseDriverModal, setChooseDriverModal] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1);
  };

  useEffect(() => {
    const fetchCorporates = async () => {
      setIsLoading(true);
      try {
        const response = await axxios.get(
          `corporates/view?page=${page}&type=prod&staffId=${id}`
        );
        setCorporate(response.data.data);
        setTotalRecords(response.data.data.total);
        setPerPage(response.data.data.per_page);
      } catch (error) {
        console.error("Error fetching corporates:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCorporates();
  }, [page, id]);

  const formatMonths = (value) => {
    const intValue = Math.floor(value);
    const decimalValue = value - intValue;
    return decimalValue > 0.09 ? `${intValue}+` : intValue;
  };

  const addDriverFormik = useFormik({
    initialValues: {
      name: "",
      phoneNumber: "",
    },
    onSubmit: async (values) => {
      try {
        await axxios.post(`/corporates/add-driver`, values);
        setAddDriverModal(false);
        addDriverFormik.resetForm();
        toast.success("Driver added", {
          position: toast.POSITION.TOP_LEFT,
          toastId: "Success",
        });
      } catch (err) {
        setAddDriverModal(false);
        addDriverFormik.resetForm();
        const errorMsg =
          err.message === "Network Error"
            ? "Network Error"
            : err.response.data.message || "Error adding driver";
        toast.error(errorMsg, {
          position: toast.POSITION.TOP_LEFT,
          toastId: "Error",
        });
      }
    },
  });

  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const response = await axxios.get(`/corporates/drivers`);
        setDrivers(response.data.data);
      } catch (error) {
        console.error("Error fetching drivers:", error);
      }
    };
    if (chooseDriverModal) {
      fetchDrivers();
    }
  }, [chooseDriverModal]);

  const handleChooseDriverClick = (rowData) => {
    setSelectedRow(rowData);
    setChooseDriverModal(true);
  };

  const handleDriverSelect = (event) => {
    setSelectedDriver(event.target.value);
  };

  const handleCloseModal = () => {
    setChooseDriverModal(false);
  };

  const handleSaveDriver = async (event) => {
    event.preventDefault();
    if (selectedDriver && selectedRow) {
      try {
        await axxios.patch(`/corporates/assign-driver/${selectedDriver}`, {
          company: selectedRow.name,
        });

        // Update the local state to reflect the assigned driver
        const updatedCorporate = corporate.details.map((item) =>
          item.name === selectedRow.name
            ? {
                ...item,
                driver: [
                  { name: drivers.find((d) => d._id === selectedDriver).name },
                ],
              }
            : item
        );

        setCorporate({ ...corporate, details: updatedCorporate });

        toast.success("Driver assigned successfully", {
          position: toast.POSITION.TOP_LEFT,
          toastId: "Success",
        });
        setChooseDriverModal(false);
      } catch (error) {
        console.error("Error assigning driver:", error);
        toast.error("Error assigning driver", {
          position: toast.POSITION.TOP_LEFT,
          toastId: "Error",
        });
      }
    } else {
      toast.error("Please select a driver", {
        position: toast.POSITION.TOP_LEFT,
        toastId: "Error",
      });
    }
  };

  return (
    <>
      <div className="staff">
        <div className="fixed">
          <Navbar />
        </div>
        <div className="container mb-4 mt-20 grow d-flex justify-content-center align-items-center">
          {isLoading ? (
            <div className="loading2">
              <div className="d-flex grow justify-content-center align-items-center">
                <BarLoader
                  color={"#59C1BD"}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            </div>
          ) : (
            <div>
              <Button
                onClick={() => setAddDriverModal(true)}
                className="btn btn-success driver-btn mb-3"
              >
                Add Driver
              </Button>
              <div className="card rounded-0">
                <DataTable
                  value={corporate?.details}
                  tableStyle={{ minWidth: "50rem" }}
                >
                  <Column
                    field="name"
                    header="Name"
                    className="uppercase"
                    body={(rowData) => (
                      <>
                        <span
                          style={{
                            width: "270px",
                            display: "inline-block",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {rowData.name}
                        </span>
                        {rowData.driver[0] && (
                          <>
                            <br />
                            <small
                              style={{
                                marginTop: "8px",
                                textTransform: "capitalize",
                              }}
                            >
                              Driver{" "}
                              <span style={{ color: "#5AB2FF" }}>
                                {rowData.driver[0].name}
                              </span>
                            </small>
                          </>
                        )}
                      </>
                    )}
                  />
                  <Column
                    className="min-w"
                    field="startDate"
                    header="Start date"
                  />
                  <Column className="min-w" field="dueDate" header="Due date" />
                  <Column
                    field="monthsPast"
                    header="Months used"
                    body={(rowData) => formatMonths(rowData.monthsPast)}
                  />
                  <Column
                    field="monthsLeft"
                    header="Months Left"
                    body={(rowData) => formatMonths(rowData.monthsLeft)}
                  />
                  <Column
                    field="status"
                    header="Status"
                    body={(rowData) => (
                      <span
                        style={{
                          backgroundColor:
                            rowData.status === "active" ? "#198754" : "#dc3545",
                          padding: "4px 8px",
                          borderRadius: "4px",
                          color: "white",
                        }}
                      >
                        {rowData.status}
                      </span>
                    )}
                  />
                  <Column
                    field="balance"
                    header="Balance"
                    body={(rowData) => (
                      <span>{rowData.balance ? rowData.balance : "---"}</span>
                    )}
                  />
                  <Column
                    field="owing"
                    header="Owing"
                    body={(rowData) => (
                      <span
                        style={{
                          backgroundColor: rowData.owing
                            ? "#198754"
                            : "#dc3545",
                          padding: "4px 8px",
                          borderRadius: "4px",
                          color: "white",
                        }}
                      >
                        {rowData.owing ? "yes" : "no"}
                      </span>
                    )}
                  />
                  <Column
                    field="driver"
                    header=""
                    body={(rowData) => (
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="secondary"
                          id="dropdown-basic"
                        >
                          {/* + */}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => handleChooseDriverClick(rowData)}
                            variant="info"
                          >
                            Choose driver
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  />
                </DataTable>
                <Paginator
                  first={first}
                  rows={perPage}
                  totalRecords={totalRecords}
                  onPageChange={onPageChange}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <Dialog
        header="Add driver"
        visible={addDriverModal}
        className="search-bar"
        onHide={() => setAddDriverModal(false)}
        draggable={false}
      >
        <form className="mt-4" onSubmit={addDriverFormik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={addDriverFormik.values.name}
              onChange={addDriverFormik.handleChange}
              placeholder="Enter name"
              className="search-bar"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="tel"
              name="phoneNumber"
              value={addDriverFormik.values.phoneNumber}
              onChange={addDriverFormik.handleChange}
              placeholder="Enter phone number"
              className="search-bar"
              required
            />
          </Form.Group>
          <Button
            type="submit"
            variant="success"
            className="rounded-0 mt-3 w-100"
          >
            Submit
          </Button>
        </form>
      </Dialog>
      <Dialog
        header="Choose driver"
        visible={chooseDriverModal}
        className="search-bar"
        onHide={handleCloseModal}
        draggable={false}
      >
        <form onSubmit={handleSaveDriver}>
          <Form.Group className="mb-3">
            <Form.Label>Drivers</Form.Label>
            <Form.Control
              as="select"
              value={selectedDriver}
              onChange={handleDriverSelect}
              required
            >
              <option value="">Select a driver</option>
              {drivers.map((driver, index) => (
                <option key={"driver" + index} value={driver._id}>
                  {driver.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Button
            type="submit"
            variant="success"
            className="rounded-0 mt-3 w-100"
          >
            Save
          </Button>
        </form>
      </Dialog>
      <ToastContainer draggable={false} transition={Zoom} autoClose={3000} />
    </>
  );
};

export default StaffDashboard;
