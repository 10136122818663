import React, { useContext } from "react";
import { Table } from "react-bootstrap";
import { MarketPlaceContext } from "../../../context/marketPlaceContext";

const TransactionTableList = ({ data }) => {
  const { sellers, markets } = useContext(MarketPlaceContext);

  const getName = (array, id) => {
    const item = array.find((item) => item._id === id);
    return item ? item.name : id;
  };

  const transactionsWithMerchantNames = data.map((transaction) => {
    const merchantName = getName(sellers, transaction.merchantId);
    return { ...transaction, merchantName };
  });

  const formatDate = (dateString) => {
    const options = { month: "short", day: "numeric", year: "numeric" };
    const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", options);
    const formattedTime = date.toLocaleTimeString("en-US", timeOptions);
    return `${formattedDate}, at ${formattedTime}`;
  };

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Merchant</th>
          <th>Market</th>
          <th>Amount</th>
          <th>Ref No.</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {transactionsWithMerchantNames.map((item, index) => (
          <tr key={item._id}>
            <td>{index + 1}</td>
            <td>{item.merchantName}</td>
            <td>{getName(markets, item.marketId)}</td>
            <td>₦ {item.amountPaid}</td>
            <td className="lowercase">{item.transactionRef}</td>
            <td className="normal">{formatDate(item.createdAt)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TransactionTableList;
