import React, { useContext, useState } from "react";
import Sidebar from "./components/Sidebar";
import { Button, Form } from "react-bootstrap";
import { MarketPlaceContext } from "../../context/marketPlaceContext";
import MarketTableList from "./components/MarketTableList";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { ToastContainer, Zoom, toast } from "react-toastify";
import { useFormik } from "formik";
import axxios from "../../api/axxios";
import { GrUserManager } from "react-icons/gr";
import { CiShop } from "react-icons/ci";

const Markets = () => {
  const { markets, setDrivers } = useContext(MarketPlaceContext);
  const [addDriverModal, setAddDriverModal] = useState(false);
  const navigate = useNavigate();

  const handleAddAgent = () => {
    navigate("/marketplace/market/create");
  };

  const addDriverFormik = useFormik({
    initialValues: {
      name: "",
      phoneNumber: "",
    },
    onSubmit: async (values) => {
      try {
        await axxios.post(`/corporates/add-driver`, values);
        setAddDriverModal(false);
        addDriverFormik.resetForm();
        const response = await axxios.get(`/corporates/drivers`);
        setDrivers(response.data.data);
        toast.success("Driver added", {
          position: toast.POSITION.TOP_LEFT,
          toastId: "Success",
        });
      } catch (err) {
        setAddDriverModal(false);
        addDriverFormik.resetForm();
        const errorMsg =
          err.message === "Network Error"
            ? "Network Error"
            : err.response.data.message || "Error adding driver";
        toast.error(errorMsg, {
          position: toast.POSITION.TOP_LEFT,
          toastId: "Error",
        });
      }
    },
  });

  return (
    <>
      <div className="d-flex">
        <Sidebar />
        <div
          className="content-container"
          style={{ flex: 1, marginLeft: "280px" }}
        >
          <div className="p-3">
            <div
              style={{ marginBottom: "20px" }}
              className="d-flex justify-content-between align-items-center"
            >
              <div className="custom-flex">
                <h2 className="mb-0">Markets</h2>
                <CiShop size={30} />
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <Button variant="success" onClick={handleAddAgent}>
                  <CiShop />
                  Add Market
                </Button>
                <Button
                  variant="success"
                  onClick={() => setAddDriverModal(true)}
                >
                  <GrUserManager />
                  Add Driver
                </Button>
              </div>
            </div>
            <MarketTableList data={markets} />
          </div>
        </div>
      </div>
      <Dialog
        header="Add driver"
        visible={addDriverModal}
        className="search-bar"
        onHide={() => setAddDriverModal(false)}
        draggable={false}
      >
        <form className="mt-4" onSubmit={addDriverFormik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={addDriverFormik.values.name}
              onChange={addDriverFormik.handleChange}
              placeholder="Enter name"
              className="search-bar"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="tel"
              name="phoneNumber"
              value={addDriverFormik.values.phoneNumber}
              onChange={addDriverFormik.handleChange}
              placeholder="Enter phone number"
              className="search-bar"
              required
            />
          </Form.Group>
          <Button
            type="submit"
            variant="success"
            className="rounded-0 mt-3 w-100"
          >
            Submit
          </Button>
        </form>
      </Dialog>
      <ToastContainer draggable={false} transition={Zoom} autoClose={3000} />
    </>
  );
};

export default Markets;
