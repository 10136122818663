import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const MarketTableList = ({ data }) => {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Senatorial District</th>
          <th>Driver</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={item._id}>
            <td>{index + 1}</td>
            <td>{item.name}</td>
            <td>{item.senatorialDistrict}</td>
            <td>{item.driver}</td>
            <td className="table-action">
              <Link
                to={`/marketplace/market/${item._id}`}
                className="btn btn-warning"
              >
                Edit
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default MarketTableList;
