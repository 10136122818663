import React, { useContext, useState } from "react";
import { Form, Button } from "react-bootstrap";
import Sidebar from "./components/Sidebar";
import { MarketPlaceContext } from "../../context/marketPlaceContext";
import axxios from "../../api/axxios";
import { useNavigate } from "react-router-dom";
import { IoPeopleCircleSharp } from "react-icons/io5";

const AddMerchant = () => {
  const { markets, paymentPlan, districts, setSellers, facilities } =
    useContext(MarketPlaceContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const staffId = sessionStorage.getItem("adminCode");
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    senatorialDistrict: "",
    facility: "",
    healthPlan: "Bronze",
    marketName: "",
    paymentPlan: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!staffId) return;

    if (formData.facility === "") {
      delete formData.facility;
    }

    try {
      setIsLoading(true);
      const response = await axxios.post(
        `/merchants/register?staffId=${staffId}`,
        formData
      );
      if (response) {
        const response = await axxios.get("/merchants");
        setSellers(response?.data.data);
        navigate("/marketplace/merchant");
      }
    } catch (error) {
      alert("Error creating merchant");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Convert paymentPlan object to an array of plan objects
  const paymentPlansArray = Object.keys(paymentPlan)
    .filter((key) => key !== "thresholdAmount") // Exclude thresholdAmount
    .map((key) => paymentPlan[key]);

  return (
    <div className="d-flex">
      <Sidebar />
      <div
        className="content-container"
        style={{ flex: 1, marginLeft: "280px" }}
      >
        <div className="p-3">
          <div
            style={{ marginBottom: "20px" }}
            className="d-flex justify-content-between align-items-center"
          >
            <div className="custom-flex">
              <h2 className="mb-0">Merchant</h2>
              <IoPeopleCircleSharp size={30} />
            </div>
          </div>

          <Form className="merchant-form" onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="phoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="senatorialDistrict">
              <Form.Label>Senatorial District</Form.Label>
              <Form.Select
                value={formData.senatorialDistrict}
                onChange={handleChange}
                name="senatorialDistrict"
                required
                aria-label=""
              >
                <option>Select district</option>
                {districts.map((district, index) => (
                  <option key={index} value={district}>
                    {district}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="facility">
              <Form.Label>Facility</Form.Label>
              <Form.Select
                name="facility"
                onChange={handleChange}
                required
                aria-label=""
              >
                <option>Select facility</option>
                {facilities.map((facility) => (
                  <option key={facility.id} value={facility.name}>
                    {facility.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="healthPlan">
              <Form.Label>Health Plan</Form.Label>
              <Form.Control
                type="text"
                name="healthPlan"
                value={formData.healthPlan}
                onChange={handleChange}
                disabled
                required
              />
            </Form.Group>

            <Form.Group controlId="marketName">
              <Form.Label>Market Name</Form.Label>
              <Form.Select
                name="marketName"
                onChange={handleChange}
                required
                aria-label=""
              >
                <option>Select market</option>
                {markets.map((market) => (
                  <option key={market._id} value={market._id}>
                    {market.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="paymentPlan">
              <Form.Label>Payment Plan</Form.Label>
              <Form.Select
                name="paymentPlan"
                onChange={handleChange}
                required
                aria-label=""
              >
                <option>Select plan</option>
                {paymentPlansArray.map((plan) => (
                  <option key={plan.id} value={plan.id}>
                    {plan.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Button variant="success" type="submit">
              {isLoading ? "Please wait" : "Submit"}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddMerchant;
