import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { MdExitToApp } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";

const NavbarComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Navbar bg="transparent">
      <Container>
        <Navbar.Brand href="/">
          <img
            alt=""
            src="https://edhicadmin.org/assets/images/logo-edo.svg"
            width="60"
            height="60"
            className="d-inline-block align-top rounded-circle"
          />{" "}
        </Navbar.Brand>
        {location.pathname.includes("/dashboard/corporate") && (
          <button
            onClick={() => {
              sessionStorage.removeItem("staffCode");
              navigate("/staff/login");
            }}
            className="logout-btn"
          >
            <MdExitToApp color="#151b15" size={30} />
          </button>
        )}
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
