import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./Theme.css";
import { EnrolleeProvider } from "./context/enrolleeContext";
import { MarketPlaceProvider } from "./context/marketPlaceContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <EnrolleeProvider>
      <MarketPlaceProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </MarketPlaceProvider>
    </EnrolleeProvider>
  </React.StrictMode>
);
