import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const isAuthenticated = !!sessionStorage.getItem("staffCode");

  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <Navigate to="/staff/login" state={{ from: location }} />
  );
};

export default ProtectedRoute;
