/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Navbar, Footer } from "../components";
import moment from "moment/moment";
import { BsCalendarDateFill, BsTelephoneFill } from "react-icons/bs";
import {
  FaUser,
  FaBook,
  FaRegAddressCard,
  FaClinicMedical,
} from "react-icons/fa";
import {
  MdOutlineHomeMax,
  MdOutlineAccountBalance,
  MdFlag,
  MdBloodtype,
  MdDateRange,
  MdOutlineQrCode,
} from "react-icons/md";
import { VscMailRead } from "react-icons/vsc";
import { GoLaw } from "react-icons/go";
import { TbGenderAgender } from "react-icons/tb";
import { BiHealth, BiIdCard } from "react-icons/bi";
import { GiCaduceus } from "react-icons/gi";
import { EnrolleeContext } from "../context/enrolleeContext";
import BarLoader from "react-spinners/BarLoader";
import axxios from "../api/axxios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const SearchResult = () => {
  const {
    enrolleeDataDetail,
    setEnrolleeDataDetail,
    isLoading,
    setIsLoading,
    dataDetailAvailable,
    setDataDetailAvailable,
    setIsError,
    setErrMessage,
  } = useContext(EnrolleeContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const [facility, setFacility] = useState(null);
  const [packName, setPackName] = useState(null);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    let url;
    setIsLoading(true);
    if (sessionStorage.getItem("isLive") === "True") {
      url = `/enrollee/view-more/${id}`;
    } else url = `/enrollee/view-more/${id}?type=test`;

    await axxios
      .get(url)
      .then((data) => {
        setIsLoading(false);
        setIsError(false);
        setDataDetailAvailable(true);
        setEnrolleeDataDetail(data.data.data);
        setFacility(data.data.data.facilities.map((facility) => facility.name));
        setPackName(data.data.data.package.name);
      })
      .catch((err) => {
        // console.log(err);
        if (err.message === "Network Error") {
          setErrMessage(
            toast.error("Network Error", {
              position: toast.POSITION.TOP_LEFT,
              toastId: "Error",
            })
          );
          setIsError(true);
          navigate("/", { replace: true });
        }
        setErrMessage(
          toast.error(err?.response?.statusText, {
            position: toast.POSITION.TOP_LEFT,
            toastId: "Error",
          })
        );
        setIsError(true);
        navigate("/", { replace: true });
      });
  };

  const enrolleesDetails = [
    {
      color: "Primary",
      header: <FaUser />,
      Title: "Name",
      Text:
        `${enrolleeDataDetail.first_name} ${enrolleeDataDetail.last_name}` ||
        "NONE",
    },
    {
      color: "Secondary",
      header: <BsCalendarDateFill />,
      Title: "Date Of Birth",
      Text: enrolleeDataDetail.dob || "NONE",
    },
    // {
    //   color: "Success",
    //   header: <FaAddressCard />,
    //   Title: "Address",
    //   Text: enrolleeDataDetail.address || "NONE",
    // },
    {
      color: "Danger",
      header: <GoLaw />,
      Title: "Local Government",
      Text: enrolleeDataDetail.lga || "NONE",
    },
    {
      color: "Warning",
      header: <MdOutlineHomeMax />,
      Title: "Ward",
      Text: enrolleeDataDetail.ward || "NONE",
    },
    {
      color: "Info",
      header: <MdOutlineAccountBalance />,
      Title: "Occupation",
      Text: enrolleeDataDetail.occupation || "NONE",
    },
    {
      color: "Light",
      header: <BsTelephoneFill />,
      Title: "Phone Number",
      Text: enrolleeDataDetail.phone || "NONE",
    },
    {
      color: "Dark",
      header: <VscMailRead />,
      Title: "Email",
      Text: enrolleeDataDetail.email || "NONE",
    },
    {
      color: "Primary",
      header: <MdFlag />,
      Title: "Nationality",
      Text: enrolleeDataDetail.nationality || "NONE",
    },
    {
      color: "Secondary",
      header: <TbGenderAgender />,
      Title: "Gender",
      Text: enrolleeDataDetail.gender || "NONE",
    },
    {
      color: "Success",
      header: <FaBook />,
      Title: "Religion",
      Text: enrolleeDataDetail.religion || "NONE",
    },
    {
      color: "Danger",
      header: <MdBloodtype />,
      Title: "Blood Group",
      Text: enrolleeDataDetail.blood_group || "NONE",
    },
    {
      color: "Warning",
      header: <BiHealth />,
      Title: "Genotype",
      Text: enrolleeDataDetail.genotype || "NONE",
    },
    {
      color: "Info",
      header: <BiIdCard />,
      Title: "Insurance ID",
      Text: enrolleeDataDetail.insurance_id || "NONE",
    },
    {
      color: "Light",
      header: <FaClinicMedical />,
      Title: "Facilites",
      Text: facility || "NONE",
    },
    {
      color: "Info",
      header: <MdDateRange />,
      Title: "Date Created",
      Text: moment(enrolleeDataDetail.created_at).format("LL") || "NONE",
    },
    {
      color: "Light",
      header: <FaRegAddressCard />,
      Title: "NIN",
      Text: enrolleeDataDetail.nin || "NONE",
    },
    {
      color: "Dark",
      header: <GiCaduceus />,
      Title: "Package Name",
      Text: packName || "NONE",
    },
    {
      color: "Primary",
      header: <MdOutlineQrCode />,
      Title: "ORACLE NUMBER",
      Text: enrolleeDataDetail.oracle_number || "NONE",
    },
  ];

  return (
    <>
      {isLoading && (
        <div className="loading">
          <Navbar />
          <div className="search-input d-flex justify-content-center align-items-center">
            <BarLoader
              color={"#59C1BD"}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </div>
      )}
      {dataDetailAvailable && (
        <>
          <Navbar />
          <div className="container mt-5">
            {enrolleeDataDetail?.s3_picture ? (
              <div className="img-container">
                <img
                  src={enrolleeDataDetail?.s3_picture}
                  className="img"
                  alt={`${enrolleeDataDetail?.first_name}`}
                />
              </div>
            ) : enrolleeDataDetail?.picture ? (
              <div className="img-container">
                <img
                  src={enrolleeDataDetail?.picture}
                  className="img"
                  alt={`${enrolleeDataDetail?.first_name}`}
                />
              </div>
            ) : (
              <p>This Enrollee does not have a profile Image</p>
            )}
          </div>
          <div className="container search-result">
            {enrolleesDetails.map((detail) => (
              <Card
                bg={detail.color.toLowerCase()}
                key={detail.Title}
                text={detail.color.toLowerCase() === "light" ? "dark" : "white"}
                // style={{ width: "15rem" }}
                className="search-card"
              >
                <Card.Header>{detail.header}</Card.Header>
                <Card.Body>
                  <Card.Title>{detail.Title}</Card.Title>
                  <Card.Text>{detail.Text}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default SearchResult;
