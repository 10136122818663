import React, { useContext, useState } from "react";
import { Form, Button } from "react-bootstrap";
import Sidebar from "./components/Sidebar";
import { MarketPlaceContext } from "../../context/marketPlaceContext";
import axxios from "../../api/axxios";
import { useNavigate } from "react-router-dom";
import { CiShop } from "react-icons/ci";

const AddMarket = () => {
  const { districts, setMarkets, drivers } = useContext(MarketPlaceContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const staffId = sessionStorage.getItem("adminCode");
  const [formData, setFormData] = useState({
    name: "",
    senatorialDistrict: "",
    driver: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!staffId) return;

    try {
      setIsLoading(true);
      const response = await axxios.post(
        `/markets/register?staffId=${staffId}`,
        formData
      );
      if (response) {
        const response = await axxios.get("/markets");
        setMarkets(response?.data.data);
        navigate("/marketplace/market");
      }
    } catch (error) {
      alert("Error creating market");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="d-flex">
      <Sidebar />
      <div
        className="content-container"
        style={{ flex: 1, marginLeft: "280px" }}
      >
        <div className="p-3">
          <div
            style={{ marginBottom: "20px" }}
            className="d-flex justify-content-between align-items-center"
          >
            <div className="custom-flex">
              <h2 className="mb-0">Markets</h2>
              <CiShop size={30} />
            </div>
          </div>

          <Form className="market-form" onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="senatorialDistrict">
              <Form.Label>Senatorial District</Form.Label>
              <Form.Select
                value={formData.senatorialDistrict}
                onChange={handleChange}
                name="senatorialDistrict"
                required
                aria-label=""
              >
                <option>Select district</option>
                {districts.map((district, index) => (
                  <option key={index} value={district}>
                    {district}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="driver">
              <Form.Label>Driver</Form.Label>
              <Form.Select
                name="driver"
                onChange={handleChange}
                required
                aria-label=""
              >
                <option>Select driver</option>
                {drivers.map((driver) => (
                  <option key={driver._id} value={driver.name}>
                    {driver.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Button variant="success" type="submit">
              {isLoading ? "Please wait" : "Submit"}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddMarket;
