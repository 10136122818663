import React, { useState, createContext, useEffect } from "react";
import axxios from "../api/axxios";

const MarketPlaceContext = createContext();

const MarketPlaceProvider = ({ children }) => {
  const [summary, setSummary] = useState({
    agents: 0,
    markets: 0,
    sellers: 0,
  });
  const [sellers, setSellers] = useState([]);
  const [markets, setMarkets] = useState([]);
  const [agents, setAgents] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [paymentPlan, setPaymentPlan] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const staffId = sessionStorage.getItem("adminCode");

  const fetchSummary = async () => {
    try {
      const response = await axxios.get("/admin/summary");
      setSummary(response?.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSellers = async () => {
    try {
      const response = await axxios.get("/merchants");
      setSellers(response?.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDrivers = async () => {
    try {
      const response = await axxios.get(`/corporates/drivers`);
      setDrivers(response.data.data);
    } catch (error) {
      console.error("Error fetching drivers:", error);
    }
  };

  const fetchFaciltes = async () => {
    if (!staffId) return;

    try {
      const response = await axxios.get(
        `/enrollee/facilities?type=prod&staffId=${staffId}`
      );
      setFacilities(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPaymentPlans = async () => {
    try {
      const response = await axxios.get("/utils/payment-plans");
      setPaymentPlan(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDistricts = async () => {
    try {
      const response = await axxios.get("/utils/districts");
      setDistricts(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMarkets = async () => {
    try {
      const response = await axxios.get("/markets");
      setMarkets(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTransactions = async () => {
    try {
      const response = await axxios.get("/admin/transactions");
      setTransactions(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAgents = async () => {
    try {
      const response = await axxios.get("/agents");
      setAgents(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSellers();
    fetchPaymentPlans();
    fetchDistricts();
    fetchMarkets();
    fetchSummary();
    fetchDrivers();
    fetchTransactions();
    fetchAgents();
  }, []);

  useEffect(() => {
    fetchFaciltes();
    // eslint-disable-next-line
  }, [staffId]);

  return (
    <MarketPlaceContext.Provider
      value={{
        summary,
        setSummary,
        sellers,
        setSellers,
        markets,
        setMarkets,
        paymentPlan,
        setPaymentPlan,
        districts,
        setDistricts,
        facilities,
        setFacilities,
        drivers,
        setDrivers,
        transactions,
        setTransactions,
        agents,
        setAgents,
      }}
    >
      {children}
    </MarketPlaceContext.Provider>
  );
};

export { MarketPlaceContext, MarketPlaceProvider };
