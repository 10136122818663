import React, { useContext } from "react";
import { Table } from "react-bootstrap";
import { MarketPlaceContext } from "../../../context/marketPlaceContext";
import { Link } from "react-router-dom";

const AgentsTableList = ({ data }) => {
  const { markets } = useContext(MarketPlaceContext);

  const getMarketName = (marketId) => {
    const market = markets.find((market) => market._id === marketId);
    return market ? market.name : marketId;
  };

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Phone Number</th>
          <th>Email</th>
          <th>Market</th>
          <th>Commission Due</th>
        </tr>
      </thead>
      <tbody>
        {data.map((agent, index) => (
          <tr key={agent._id}>
            <td>{index + 1}</td>
            <td>{agent.name}</td>
            <td>{agent.phoneNumber}</td>
            <td className="lowercase">{agent.email}</td>
            <td>{getMarketName(agent.marketId)}</td>
            <td>₦ {agent.commissionDue}</td>
            <td className="table-action">
              <Link
                to={`/marketplace/agent/${agent._id}`}
                className="btn btn-warning"
              >
                Edit
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default AgentsTableList;
