import React, { useContext } from "react";
import Sidebar from "./components/Sidebar";
import { Button } from "react-bootstrap";
import AgentsTableList from "./components/AgentsTableList";
import { MarketPlaceContext } from "../../context/marketPlaceContext";
import { useNavigate } from "react-router-dom";
import { FaRegUser } from "react-icons/fa";

const Agent = () => {
  const { agents } = useContext(MarketPlaceContext);
  const navigate = useNavigate();

  const handleAddAgent = () => {
    navigate("/marketplace/agent/create");
  };

  return (
    <div className="d-flex">
      <Sidebar />
      <div
        className="content-container"
        style={{ flex: 1, marginLeft: "280px" }}
      >
        <div className="p-3">
          <div
            style={{ marginBottom: "20px" }}
            className="d-flex justify-content-between align-items-center"
          >
            <div className="custom-flex">
              <h2 className="mb-0">Agents</h2>
              <FaRegUser size={25} />
            </div>
            <Button variant="success" onClick={handleAddAgent}>
              Add Agent
            </Button>
          </div>
          <AgentsTableList data={agents} />
        </div>
      </div>
    </div>
  );
};

export default Agent;
