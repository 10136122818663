import React, { useContext, useEffect } from "react";
import { Navbar, SearchBar } from "../components";
import { EnrolleeContext } from "../context/enrolleeContext";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

const Search = () => {
  const { isError, radioValue, setRadioValue } = useContext(EnrolleeContext);

  const radios = [
    { name: "Test", value: "1" },
    { name: "Live", value: "2" },
  ];

  useEffect(() => {
    sessionStorage.setItem("isLive", "True");
  }, []);

  return (
    <div className="main-search">
      <Navbar />
      <div className="search-input d-flex justify-content-center align-items-center">
        <div className="z-3 position-fixed top-0 end-0 mt-4 me-4">
          <ButtonGroup>
            {radios.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant={idx % 2 ? "outline-success" : "outline-danger"}
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={(e) => setRadioValue(e.currentTarget.value)}
                onClick={() => {
                  radio.name === "Live" &&
                    sessionStorage.setItem("isLive", "True");
                  radio.name === "Test" && sessionStorage.removeItem("isLive");
                }}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>
        <ToastContainer draggable={false} autoClose={3000} transition={Zoom} />

        <SearchBar />

        {isError && (
          <ToastContainer
            draggable={false}
            autoClose={3000}
            transition={Zoom}
          />
        )}
      </div>
    </div>
  );
};

export default Search;
