import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";

const AdminProtectedRoute = ({ children }) => {
  const location = useLocation();
  const isAuthenticated = !!sessionStorage.getItem("adminCode");

  return isAuthenticated ? (
    <div className="marketplace">
      {children}
      <ToastContainer draggable={false} autoClose={3000} transition={Zoom} />
    </div>
  ) : (
    <Navigate to="/marketplace" state={{ from: location }} />
  );
};

export default AdminProtectedRoute;
