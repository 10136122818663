import React, { useContext } from "react";
import TableList from "./components/TableList";
import Sidebar from "./components/Sidebar";
import { Button } from "react-bootstrap";
import { MarketPlaceContext } from "../../context/marketPlaceContext";
import { useNavigate } from "react-router-dom";
import { IoPeopleCircleSharp } from "react-icons/io5";

const Merchant = () => {
  const { sellers } = useContext(MarketPlaceContext);
  const navigate = useNavigate();

  const handleAddMerchant = () => {
    navigate("/marketplace/merchant/create");
  };

  return (
    <div className="d-flex">
      <Sidebar />
      <div
        className="content-container"
        style={{ flex: 1, marginLeft: "280px" }}
      >
        <div className="p-3">
          <div
            style={{ marginBottom: "20px" }}
            className="d-flex justify-content-between align-items-center"
          >
            <div className="custom-flex">
              <h2 className="mb-0">Merchant</h2>
              <IoPeopleCircleSharp size={30} />
            </div>
            <Button variant="success" onClick={handleAddMerchant}>
              Add Merchant
            </Button>
          </div>
          <TableList data={sellers} />
        </div>
      </div>
    </div>
  );
};

export default Merchant;
