/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Footer, Navbar } from "../components";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import axxios from "../api/axxios";
import { useNavigate, useParams } from "react-router-dom";
import BarLoader from "react-spinners/BarLoader";
import { EnrolleeContext } from "../context/enrolleeContext";
import { MultiSelect } from "primereact/multiselect";
import { toast, ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "react-bootstrap";

const EditEnrolleDetails = () => {
  const [lgas, setLgas] = useState([]);
  const [selectedPictureFile, setSelectedPictureFile] = useState(null);
  const [selectedPictureBinary, setSelectedPictureBinary] = useState("");
  const [selectedPictureUrl, setSelectedPictureUrl] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const { isLoading, setIsLoading, dataAvailable, setDataAvailable } =
    useContext(EnrolleeContext);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showEditError, setShowEditError] = useState(false);
  const [editErrorMessage, setEditErrorMessage] = useState("");

  useEffect(() => {
    getDetails();
    getLga();
  }, []);

  const getDetails = async () => {
    setIsLoading(true);
    let url;
    if (sessionStorage.getItem("isLive") === "True") {
      url = `/enrollee/view-more/${id}`;
    } else url = `/enrollee/view-more/${id}?type=test`;

    await axxios.get(url).then((data) => {
      setSelectedPictureUrl(data.data.data.picture);
      setSelectedFacilities(data.data.data.facilities);
      setForm({
        first_name: data.data.data.first_name ? data.data.data.first_name : "",
        last_name: data.data.data.last_name ? data.data.data.last_name : "",
        middle_name: data.data.data.middle_name
          ? data.data.data.middle_name
          : "",
        dob: data.data.data.dob ? data.data.data.dob : "",
        address: data.data.data.address ? data.data.data.address : "",
        lga: data.data.data.lga ? data.data.data.lga : "",
        nin: data.data.data.nin ? data.data.data.nin : "",
        lga_code: data.data.data.lga_code ? data.data.data.lga_code : "",
        occupation: data.data.data.occupation ? data.data.data.occupation : "",
        phone: data.data.data.phone ? data.data.data.phone : "",
        gender: data.data.data.gender ? data.data.data.gender : "",
        religion: data.data.data.religion ? data.data.data.religion : "",
        genotype: data.data.data.genotype ? data.data.data.genotype : "",
        blood_group: data.data.data.blood_group
          ? data.data.data.blood_group
          : "",
        allergies: data.data.data.allergies ? data.data.data.allergies : "",
        business_name: data.data.data.business_name
          ? data.data.data.business_name
          : "",
        office_address: data.data.data.office_address
          ? data.data.data.office_address
          : "",
        office_lga: data.data.data.office_lga ? data.data.data.office_lga : "",
        marital_status: data.data.data.marital_status
          ? data.data.data.marital_status
          : "",
        photo: null,
        picture: null,
        facilities: data.data.data.facilities,
      });
      setDataAvailable(true);
      setIsLoading(false);
    });
  };

  const getLga = async () => {
    setIsLoading(true);
    await axxios.get("/enrollee/lgas").then((data) => {
      setLgas(data.data.data);
    });
  };

  const [form, setForm] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    dob: "",
    address: "",
    lga: "",
    lga_code: "",
    occupation: "",
    phone: "",
    nin: "",
    gender: "",
    photo: null,
    picture: "",
    religion: "",
    genotype: "",
    blood_group: "",
    allergies: "",
    business_name: "",
    office_address: "",
    office_lga: "",
    marital_status: "",
    facilities: [],
  });

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  };

  const handleFileInput = async (event) => {
    try {
      setForm({ ...form, picture: event.target.value });
      const file = event.target.files[0];
      setSelectedPictureFile(file);
      const base64 = await readFileAsBase64(file);
      setSelectedPictureBinary(base64);
      // console.log("File is:", file);
      // console.log("Base64 is:", base64);
      setForm({ ...form, photo: event.target.value });
    } catch (error) {
      // console.log(error);
    }
  };

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    let url;
    let values;

    // check if any facilities are selected
    if (selectedFacilities.length === 0) {
      setIsSubmitting(false);
      toast.warn("Select at least one facility", {
        position: toast.POSITION.TOP_LEFT,
        toastId: "Warning",
      });
      return;
    }

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      if (selectedPictureFile) {
        values = {
          ...form,
          photo: selectedPictureFile,
          facilities: `[${selectedFacilities
            .map((facility) => `${facility.id}`)
            .join(", ")}]`,
        };
      } else {
        values = {
          ...form,
          picture: selectedPictureUrl,
          facilities: `[${selectedFacilities
            .map((facility) => `${facility.id}`)
            .join(", ")}]`,
        };
      }

      if (sessionStorage.getItem("isLive") === "True") {
        url = `/enrollee/edit/${id}`;
      } else {
        url = `/enrollee/edit/${id}?type=test`;
      }
      await axxios.post(url, values, config);
      navigate(`/enrollee/details/${id}`);
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error("Network Error", {
          position: toast.POSITION.TOP_LEFT,
          toastId: "Error",
        });
      }
      if (Object.keys(error.response.data?.data).length === 0) {
        const message = error.response.data?.message;
        setEditErrorMessage(message);
        setShowEditError(true);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading && !dataAvailable) {
    return (
      <div className="loading">
        <Navbar />
        <div className="search-input d-flex justify-content-center align-items-center">
          <BarLoader
            color={"#59C1BD"}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </div>
    );
  }

  return (
    <>
      {showEditError && (
        <Alert
          variant="danger"
          onClose={() => setShowEditError(false)}
          dismissible
          className="container mt-2 position-fixed top-0 start-0 end-0"
        >
          <Alert.Heading>Error!</Alert.Heading>
          <p>{editErrorMessage}</p>
          <Button
            variant="outline-danger"
            onClick={() => navigate(`/enrollee/details/${id}`)}
          >
            Go back
          </Button>
        </Alert>
      )}
      <Navbar />
      <ToastContainer draggable={false} autoClose={3000} transition={Zoom} />
      <div className="container mb-4 mt-4">
        <h5 className="mb-2 mt-4">ENROLLEE DETAILS UPDATE</h5>
        <p>NB: Fill all required fields even if they are not changed.</p>
        <form onSubmit={handleSubmit}>
          <Row>
            <Form.Group className="mb-4" as={Col} md="6">
              <Form.Label className="mb-2" htmlFor="first_name">
                First Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="first_name"
                required
                placeholder="First Name"
                value={form.first_name}
                onChange={handleChange}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-4" as={Col} md="6">
              <Form.Label className="mb-2" htmlFor="id">
                Last Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="last_name"
                required
                placeholder="Last Name"
                value={form.last_name}
                onChange={handleChange}
                disabled
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-4" as={Col} md="6">
              <Form.Label className="mb-2" htmlFor="middle_name">
                Middle Name
              </Form.Label>
              <Form.Control
                type="text"
                name="middle_name"
                placeholder="Middle Name"
                value={form.middle_name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-4" as={Col} md="6">
              <Form.Label className="mb-2" htmlFor="id">
                Date Of Birth <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="date"
                name="dob"
                required
                value={form.dob}
                onChange={handleChange}
                placeholder="Birth Date"
                disabled={form.business_name !== "Igbinedion University Okada"}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-4" as={Col} md="6">
              <Form.Label className="mb-2" htmlFor="id">
                Residential Address <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="address"
                required
                value={form.address}
                onChange={handleChange}
                placeholder="Address"
              />
            </Form.Group>
            <Form.Group className="mb-4" as={Col} md="6">
              <Form.Label className="mb-2" htmlFor="id">
                LGA of residence <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Select
                name="lga"
                required
                value={form.lga}
                onChange={handleChange}
              >
                <option value="">-LGA-</option>
                {lgas.map((lga) => (
                  <option key={lga.code} value={lga.name}>
                    {lga.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-4" as={Col} md="6">
              <Form.Label className="mb-2" htmlFor="id">
                Occupation
              </Form.Label>
              <Form.Control
                type="text"
                name="occupation"
                value={form.occupation}
                onChange={handleChange}
                placeholder="Occupation"
              />
            </Form.Group>
            <Form.Group className="mb-4" as={Col} md="6">
              <Form.Label className="mb-2" htmlFor="id">
                NIN
              </Form.Label>
              <Form.Control
                type="text"
                name="nin"
                placeholder="NIN"
                value={form.nin}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-4" as={Col} md="6">
              <Form.Label className="mb-2" htmlFor="id">
                Phone
              </Form.Label>
              <Form.Control
                type="text"
                name="phone"
                placeholder="Phone Number"
                value={form.phone}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-4" as={Col} md="6">
              <Form.Label className="mb-2" htmlFor="id">
                Gender <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Select
                name="gender"
                value={form.gender}
                onChange={handleChange}
                required
              >
                <option value="">-Gender-</option>
                {[
                  { value: "Male", code: "M" },
                  { value: "Female", code: "F" },
                ].map((gender) => (
                  <option key={gender.code} value={gender.name}>
                    {gender.value}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-4" as={Col} md="6">
              <Form.Label className="mb-2" htmlFor="id">
                Religion <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Select
                name="religion"
                value={form.religion}
                onChange={handleChange}
                required
              >
                <option>-Religion-</option>
                {[
                  { value: "Christian", code: "C" },
                  { value: "Muslim", code: "M" },
                  { value: "Traditional", code: "T" },
                  { value: "Others", code: "O" },
                ].map((religion) => (
                  <option key={religion.code} value={religion.name}>
                    {religion.value}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-4" as={Col} md="6">
              <Form.Label className="mb-2" htmlFor="id">
                Marital Status <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Select
                name="marital_status"
                value={form.marital_status}
                onChange={handleChange}
                required
              >
                <option>-Marital Status-</option>
                {[
                  "Married",
                  "Single",
                  "Widow",
                  "Widower",
                  "Single Parent",
                  "Divorced",
                ].map((marital_status) => (
                  <option key={marital_status} value={marital_status}>
                    {marital_status}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-4" as={Col} md="6">
              <Form.Label className="mb-2" htmlFor="id">
                Genotype
              </Form.Label>
              <Form.Select
                name="genotype"
                value={form.genotype}
                onChange={handleChange}
              >
                <option>-Genotype-</option>
                {["AA", "AS", "SS", "AC", "SC"].map((genotype) => (
                  <option key={genotype} value={genotype}>
                    {genotype}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-4" as={Col} md="6">
              <Form.Label className="mb-2" htmlFor="id">
                Blood Group
              </Form.Label>
              <Form.Select
                name="blood_group"
                value={form.blood_group}
                onChange={handleChange}
              >
                <option value="">-Blood Group-</option>
                {["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"].map(
                  (blood_group) => (
                    <option key={blood_group} value={blood_group}>
                      {blood_group}
                    </option>
                  )
                )}
              </Form.Select>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-4" as={Col} md="6">
              <Form.Label className="mb-2" htmlFor="id">
                Allergies
              </Form.Label>
              <Form.Control
                type="text"
                name="allergies"
                placeholder="Allergies"
                value={form.allergies}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-4" as={Col} md="6">
              <Form.Label className="mb-2" htmlFor="id">
                Business Name
              </Form.Label>
              <Form.Control
                type="text"
                name="business_name"
                placeholder="Business Name"
                value={form.business_name}
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-4" as={Col} md="6">
              <Form.Label className="mb-2" htmlFor="office_address">
                Office Address <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="office_address"
                required
                placeholder="Office Address"
                value={form.office_address}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-4" as={Col} md="6">
              <Form.Label className="mb-2" htmlFor="office_lga">
                Office LGA <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Select
                name="office_lga"
                required
                value={form.office_lga}
                onChange={handleChange}
              >
                <option value="">-LGA-</option>
                {lgas.map((lga) => (
                  <option key={lga.code} value={lga.name}>
                    {lga.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
          <Row>
            {/* <Form.Group className="mb-4" as={Col} md="6">
              <Form.Label className="mb-1" htmlFor="id">
                Primary Facility
              </Form.Label>
              <MultiSelect
                value={selectedFacilities}
                name="facilities"
                onChange={(event) => setSelectedFacilities(event.value)}
                options={facilities}
                optionLabel="name"
                display="chip"
                placeholder="Select..."
                maxSelectedLabels={3}
                className="w-full md:w-20rem"
                style={{ width: "100%" }}
              />
            </Form.Group> */}
            <Form.Group className="mb-4" as={Col} md="6">
              <Form.Label className="mb-2" htmlFor="facilities">
                Primary Facility
              </Form.Label>
              <Form.Control
                type="text"
                name="facilities"
                placeholder="Primary Facility"
                value={form.facilities.map((facility) => `${facility.name}, `)}
                onChange={handleChange}
                disabled
              />
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-4" as={Col} md="6">
              <Form.Label>Picture</Form.Label>
              <Form.Control
                type="file"
                name="picture"
                value={form.picture}
                onChange={handleFileInput}
              />
            </Form.Group>
          </Row>
          <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-3 w-100 ms-auto">
            <button type="submit" className="btn btn-success w-100">
              {isSubmitting ? "UPDATING..." : "UPDATE"}
            </button>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default EditEnrolleDetails;
