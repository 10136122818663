import React, { useContext, useState } from "react";
import { Form, Button } from "react-bootstrap";
import Sidebar from "./components/Sidebar";
import { MarketPlaceContext } from "../../context/marketPlaceContext";
import { useNavigate } from "react-router-dom";
import axxios from "../../api/axxios";
import { FaRegUser } from "react-icons/fa";

const AddAgent = () => {
  const { markets, setAgents } = useContext(MarketPlaceContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    marketId: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      const response = await axxios.post("/agents/register", formData);
      if (response) {
        const response = await axxios.get("/agents");
        setAgents(response?.data.data);
        navigate("/marketplace/agent");
      }
    } catch (error) {
      alert("Error creating agent");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="d-flex">
      <Sidebar />
      <div
        className="content-container"
        style={{ flex: 1, marginLeft: "280px" }}
      >
        <div className="p-3">
          <div
            style={{ marginBottom: "20px" }}
            className="d-flex justify-content-between align-items-center"
          >
            <div className="custom-flex">
              <h2 className="mb-0">Agents</h2>
              <FaRegUser size={25} />
            </div>
          </div>

          <Form className="agent-form" onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="phoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="marketId">
              <Form.Label>Market</Form.Label>
              <Form.Select
                name="marketId"
                value={formData.marketId}
                onChange={handleChange}
                required
                aria-label=""
              >
                <option>Select market</option>
                {markets.map((market) => (
                  <option key={market._id} value={market._id}>
                    {market.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Button variant="success" type="submit">
              {isLoading ? "Please wait" : "Submit"}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddAgent;
